<template>
  <div class="orders">
    <h1 class="title">Orders</h1>

    <table class="table is-fullwidth">
      <thead>
        <th>ID</th>
        <th>Customer</th>
        <th>Source</th>
        <th>Status</th>
      </thead>
      <tbody>
        <tr @click="navigate(order.id)" v-for="order in orders">
          <td>{{order.id}}</td>
          <td>{{order.customer_name}} @ {{order.customer_company}}</td>
          <td>{{order.source}}</td>
          <td><div class="tag is-primary">{{order.status}}</div></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Orders from "@/api/Orders"

export default {
  name: 'Orders',
  data() {
    return {
      orders: []
    }
  },
  methods: {
    navigate(id) {
      this.$router.push("/dashboard/orders/" + id)
    }
  },
  mounted: function() {
    Orders.getAll().then(res => {this.orders = res})
  }
}
</script>
<style scoped>
  td {
    text-transform: capitalize;
  }
</style>