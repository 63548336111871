<template>
  <div class="customerName" v-if="billing">
    <span v-if="firstName.length > 0">{{firstName + " "}}</span>
    <span v-if="lastName.length > 0">{{lastName + " "}}</span>
    <span class="tag is-light" v-if="company.length > 0">{{company}}</span>
  </div>
</template>

<script>
export default {
  name: 'Customer Name',
  props: {
    billing: Object
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase().trim()
      })
    }
  },
  computed: {
    firstName: function() {
      if(this.billing.first_name) {
        return this.toTitleCase(this.billing.first_name)
      } else {
        return ""
      }
    },
    lastName: function() {
      if(this.billing.last_name) {
        return this.toTitleCase(this.billing.last_name)
      } else {
        return ""
      }
    },
    company: function() {
      if(this.billing && this.billing.company) {
        return this.toTitleCase(this.billing.company)
      } else {
        return ""
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tag {
    border: 1px solid #ccc;
    margin-left: 5px;
  }
</style>
