<template>
  <div class="websiteOrders">
    <div>
      <h1 class="title">Website Orders</h1>
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <th>ID</th>
          <th>Received</th>
          <th>Customer</th>
          <th>Status</th>
        </thead>
        <tbody>
          <tr @click="openOrder(order.uid)" v-for="order in webOrders" :key="order.id">
            <td>{{getOrderNumber(order)}}</td>
            <td><ODate :date="order.date_created"></ODate></td>
            <td><CustomerName :billing="order.billing"></CustomerName></td>
            <td><OrderStatus :status="order.status"></OrderStatus></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomerName from "@/components/dashboard/website/orders/CustomerName"
import OrderStatus from "@/components/dashboard/website/orders/OrderStatus"
import ODate from "@/components/dashboard/website/orders/Date"

export default {
  name: 'Website Orders',
  components: {
    CustomerName,
    OrderStatus,
    ODate
  },
  mounted: function() {
    this.$store.dispatch("WebsiteOrders/setOrders")
    setTimeout(this.printOrders, 2000)
  },
  methods: {
    printOrders() {
      console.log(this.webOrders)
    },
    openOrder(uid) {
      this.$router.push("/dashboard/orders/website/" + uid)
    },
    getOrderNumber(order) {
      if(order.meta_data) {
        for(var i = 0; i < order.meta_data.length; i++) {
          var cur = order.meta_data[i]

          if(cur.key == "_alg_wc_custom_order_number") {
            return cur.value
          }
        }
      }

      return order.id
    }
  },
  computed: {
  ...mapGetters({
      _orders: "WebsiteOrders/getOrders",
    }),
    webOrders() {
      if(this._orders) {
        var orders = this._orders.sort((a,b) => {
          return new Date(b.date_created) - new Date(a.date_created)
        })
        return orders
      } else {
        return []
      }

    }
  }
  
}
</script>
<style scoped>
  tr {
    cursor: pointer;
    transition: .25s ease;
  }

  td {

  }

  tr:hover {
    transform: scale(1.01);
  }

  tr:hover td {
        background-color: #eee;

  }
</style>