import Database from "./Database"
const DATABASE_ORDERS = "_orders_test"

class WMOrders {

    getAll() {
        return Database.getAll(DATABASE_ORDERS)
    }

    getOne(id) {
        return Database.getOne(DATABASE_ORDERS, id)
    }
}

const Orders = new WMOrders()
export default Orders