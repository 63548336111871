<template>
  <tr class="lineItem" v-if="product">
    <td>
      <img :src="product.images[0]"/>
    </td>
    <td>
      <h3>{{product.name}}</h3>
      <small>SKU: {{product.sku}}</small>
      <small>Variation ID: {{lineItem.variation_id}}</small>
    </td>
    <td>
      ${{lineItem.price}}
    </td>
    <td>
      x{{lineItem.quantity}}
    </td>
    <td>
      ${{lineItem.total}}
    </td>
  </tr>
  <tr v-else>
    <Loader :is-active="true"></Loader>
  </tr>
</template>

<script>
import Loader from "@/components/global/Loader"
import Products from "@/api/ProductListings"

export default {
  name: 'Website Order Line Item',
  props: {
    lineItem: Object
  },
  data() {
    return {
      loading: true,
      product: undefined
    }
  },
  methods: {
    setProduct(response) {
      if(response) {
        if(response.success != false) {
          this.product = response
          this.loading = false
        } 

      }

    }
  },
  mounted: function() {
    var productID = this.lineItem.product_id
    Products.getByID(productID).then(this.setProduct)
  },
  components: {
    Loader
  }
}
</script>

<style scoped>

  img {
    height: 75px;
    width: 75px;
    object-fit: contain;
  }

  td h3 {
    font-weight: bold;
  }

  td small {
    display: block;
  }

  .lineItem {
    width: 100%;
    background-color: #eee;
    height: 100px;
  }
</style>
