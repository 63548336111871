<template>
  <div class="order">
    <h1 class="title">Order #{{ id }}</h1>
    <div class="columns" v-if="order">
      <div class="column order-info">
        <table class="table items is-fullwidth">
          <thead>
            <th>Quantity</th>
            <th>Item</th>
            <th>Description</th>
            <th>Rate</th>
            <th>Total</th>
          </thead>
          <tbody>
            <tr v-for="item in order.items">
              <td>{{ item.quantity }}</td>
              <td>{{ item.item }}</td>
              <td>{{ item.description }}</td>
              <td>{{ !isNaN(item.cost)? "$" + item.cost.toFixed(2) : item.cost }}</td>
              <td>${{ item.total.toFixed(2) }}</td>
            </tr>
          </tbody>
          <tfoot>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>${{ itemsTotal.toFixed(2) }}</td>
          </tfoot>
        </table>

        <div class="columns">
          <div class="column panel">
            <h2 class="heading">Documents</h2>
          </div>
          <div class="column panel">
            <h2 class="heading">Notes</h2>
            <ul class="updates">
              <li>
                <span class="msg"
                  >This customer was very mean to me :(</span
                >
                <span class="date">November 17th at 4:23PM by Charlie H.</span>
              </li>
            </ul>
            <a href="#" class="button is-small">Add a Note</a>
          </div>
        </div>
      </div>

      <div class="column order-sidebar is-one-quarter">
        <Address
          class="address"
          title="Billing"
          :address="order.shipping_address"
        ></Address>

        <Address
          class="address"
          title="Shipping"
          :address="order.shipping_address"
        ></Address>
        <div class="block">
          <h2 class="heading">Status</h2>
          <div class="tag is-primary is-medium is-capitalized">
            {{ order.status }}
          </div>
        </div>
        <div class="block">
          <h2 class="heading">Source</h2>
          <div class="tag is-medium is-capitalized">
            {{ order.source }}
          </div>
        </div>
        <div class="block sales_person">
          <h2 class="heading">Sales Representative</h2>
          <span class="name"
            ><div class="icon"><i class="fas fa-user"></i></div>
            John McTester</span
          >
          <span class="phone"
            ><div class="icon"><i class="fas fa-mobile-alt"></i></div>
            (292) 493-2848</span
          >
          <span class="email"
            ><div class="icon"><i class="fas fa-envelope"></i></div>
            <a href="#">johnnytester@moscadesign.com</a></span
          >
        </div>
        <div class="block sales_person">
          <h2 class="heading">Customer Service</h2>
          <span class="name"
            ><div class="icon"><i class="fas fa-user"></i></div>
            Peter Testaroni</span
          >
          <span class="phone"
            ><div class="icon"><i class="fas fa-mobile-alt"></i></div>
            (879) 456-5345</span
          >
          <span class="email"
            ><div class="icon"><i class="fas fa-envelope"></i></div>
            <a href="#">ptestaroni@moscadesign.com</a></span
          >
        </div>
        <div class="block">
          <h2 class="heading">Updates</h2>
          <ul class="updates">
            <li>
              <span class="msg">Order has been received.</span>
              <span class="date">November 16th, 2021 at 2:32PM</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Address from "@/components/global/fields/Address";
import Orders from "@/api/Orders";

export default {
  name: "Order",
  data() {
    return {
      order: false,
    };
  },
  components: {
    Address,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    itemsTotal() {
      return this.order.items.reduce((prev, cur) => {
        return prev + cur.total
      }, 0)
    }
  },
  mounted: function () {
    Orders.getOne(this.id).then((res) => {
      this.order = res;
    });
  },
};
</script>
<style scoped>
.address {
  margin-bottom: 25px;
}

.updates li {
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.updates li .msg {
  margin-bottom: 0px;
}

.updates li .date {
  font-size: 0.7em;
  opacity: 0.75;
}

.panel {
  border: 1px solid #ddd;
  min-height: 200px;
}

.panel:first-child {
  margin-right: 15px;
}

.items {
  margin-bottom: 50px;
}

.sales_person {
  display: flex;
  flex-direction: column;
}

.sales_person span i {
  padding-right: 5px;
}

.order-info {
  padding-right: 50px;
  border-right: 1px solid #ccc;
}

.order-sidebar {
  padding-left: 25px;
}
</style>