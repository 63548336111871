<template>
  <div class="orderStatus">
    <span :class="'tag is-medium is-light ' + tagColorClass" v-if="status">{{statusText}}</span>
    <span class="tag" v-else>?</span>
  </div>
</template>

<script>
export default {
  name: 'Order Status',
  props: {
    status: String
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase().trim()
      })
    }
  },
  computed: {
    statusText() {
      return this.toTitleCase(this.status)
    },
    tagColorClass() {
      switch(this.status) {
        case "completed":
          return "is-primary"
        case "on-hold":
          return "is-warning"
        case "cancelled":
          return "is-danger"
        case "processing":
          return "is-link"

        default: 
          break
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
