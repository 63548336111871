<!-- 
Module to output an address object. Expects the following fields: 

- line1
- city
- state
- zip
- name
- company
-->
<template>
  <div class="address">
    <div class="block">
      <span class="heading">{{title}}</span>
      <span>{{address.name}}</span>
      <span>{{address.company}}</span>
      <span>{{address.line1}}</span>
      <span>{{address.city}}, {{address.state}} {{address.zip}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Address',
  props: {
    address: Object,
    title: String
  }
}
</script>
<style scoped>
  .address .address_field_title {
    font-weight: bold;
  }

  .address .block {
    display: flex;
    flex-direction: column;
  }
</style>
