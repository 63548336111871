<template>
  <div class="websiteOrder">
    <div v-if="order">

      <div class="columns is-multiline">
        <div class="column">
          <router-link to="/dashboard/orders/website" class="button">Back</router-link>
        </div>
    
        <div class="column" style="display: flex; justify-content: flex-end;">
          <button @click="viewOrderOnline" class="button">View Order on Website</button>

        </div>
        <div class="column is-full">
          <h1 class="title">Order #{{order.id}}</h1>
        </div>
      </div>


      <div class="columns">
        <div class="column is-8">
          <div class="lineItems">
            <h2>Items ({{order.line_items.length}})</h2>
            <table class="table is-fullwidth">
              <thead>
                <th>Item</th>
                <th></th>
                <th>Cost</th>
                <th>Qty</th>
                <th>Total</th>
              </thead>
              <tbody>
                <LineItem :lineItem="item" v-for="item in order.line_items"></LineItem>
              </tbody>
            </table>

            <small>Shipping Method</small>
            <div class="shippingMethod columns" v-for="shippingLine in order.shipping_lines">
              <div class="column is-half">
                {{shippingLine.method_title}}
              </div>
              <div class="column is-half has-text-right">
                ${{shippingLine.total}}
              </div>
            </div>

            <div class="columns">
              <div class="column has-text-right">
                <table class="table is-fullwidth">
                  <thead>
                    <th style="width: 80%;"></th>
                    <th></th>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Items Subtotal:</td>
                      <td>${{subtotal}}</td>
                    </tr>
                    <tr>
                      <td>Shipping:</td>
                      <td>${{order.shipping_total}}</td>
                    </tr>
                    <tr>
                      <td>Tax:</td>
                      <td>${{order.total_tax}}</td>
                    </tr>
                    <tr>
                      <td>Order Total:</td>
                      <td><b>${{order.total}}</b></td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div> 

          </div>
        </div>

        <div class="column">
          <div class="panel">
            <h2>Status</h2>
            <h3 style="text-transform: capitalize;">{{order.status}}</h3>
            <a href="#" @click="updateStatus('completed')" v-if="order.status != 'completed'">Mark as completed</a>
          </div>
          <div class="panel">
            <h2>Shipping Details</h2>
            <h3>{{order.shipping.first_name}} {{order.shipping.last_name}}</h3>
            <h3>{{order.shipping.company}}</h3>
            <h3>{{order.shipping.address_1}}</h3>
            <h3>{{order.shipping.address_2}}</h3>
            <h3>{{order.shipping.city}}, {{order.shipping.state}} {{order.shipping.postcode}}</h3>
          </div>

          <div class="panel">
            <h2>Billing Details</h2>
            <h3>{{order.billing.first_name}} {{order.billing.last_name}}</h3>
            <h3>{{order.billing.company}}</h3>

            <h3>{{order.billing.address_1}}</h3>
            <h3>{{order.billing.address_2}}</h3>
            <h3>{{order.billing.city}}, {{order.billing.state}} {{order.shipping.postcode}}</h3>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import WebsiteOrders from "@/api/WebsiteOrders"
import LineItem from "@/components/dashboard/website/orders/LineItem"

export default {
  name: 'Website Order',
  data() {
    return {
      order: undefined
    }
  },
  components: {
    LineItem
  },
  methods: {
    viewOrderOnline() {
      var url ="https://moscadesign.com/wp-admin/post.php?post=" + this.order.id + "&action=edit"
      window.open(url, '_blank').focus();

    },
    setOrder(response) {
      this.order = response
      this.getLineItems()
    },
    updateStatus(status) {
      this.$toast.create("Updating order status...")
      WebsiteOrders.updateStatus(this.order.id, status).then(res => {
        this.order.status = status
        this.$toast.create("Order status updated successfully")
        console.log("RESPONSE", res)

        if(res == false) {
          this.$toast.create("Failed to update order status")
        }
      }).catch(err => {
        console.log("Error updating order status", err)
        this.$toast.create("Failed to update order status")
      })
    },
    getLineItems() {

    },
    requestOrder() {
      WebsiteOrders.getOne(this.uid).then(this.setOrder).catch((error) => {
        console.log("ERROR", error)
      })
    }
  },
  mounted: function() {
    this.requestOrder()
  },
  computed: {
    subtotal() {
      var subtotal = 0

      this.order.line_items.forEach(item => {
        subtotal += Number(item.subtotal)
      })

      return subtotal.toFixed(2)
    },
    uid() {
      return this.$route.params.uid
    }
  }
}
</script>
<style scoped>
  .panel {
    margin-bottom: 25px;
    border: 1px solid #eee;
    padding: 15px;
  }

  .panel h2 {
    font-weight: bold;
  }

  .panel h3 {
    text-transform: capitalize;
  }
</style>
