<template>
  <div class="orderDate" v-if="dateObj">
    <span>{{month}} {{day}}, {{year}} at {{hour}}:{{minute}}{{AMPM}}</span>
  </div>
</template>

<script>
export default {
  name: 'Order Date',
  props: {
    date: String
  },
  data() {
    return {
      dateObj: undefined,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    }
  },
  methods: {
    setDateObj() {
      if(!this.dateObj) {
        this.dateObj = new Date(this.date)
      }
    },
    getOrdinalNum(n) {
        return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    }
  },
  mounted: function() {
    this.setDateObj()
  },
  computed: {
    dateString() {
      return this.month + " " + this.dateObj.getDay()
    },
    year() {
      return this.dateObj.getFullYear()
    },
    month() {
      return this.months[this.dateObj.getMonth()]
    },
    day() {
      return this.getOrdinalNum(this.dateObj.getDate())
    },
    hour() {
      return ((this.dateObj.getHours() + 11) % 12 + 1)
    },
    minute() {
      return this.dateObj.getMinutes()
    },
    AMPM() {
      return this.dateObj.getHours() > 11? "PM" : "AM"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
